import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        show: false,
        products: [],
        selectedProducts: [],
        filter: {
            name: '',
            barcode: '',
            limit: 10,
            brand_id: [],
            page: 1,
            sorting: 'DESC'
        }
    },
    getters: {
        _show(state) {
            return state.show
        },
        _products(state) {
            return state.products
        },
        _selectedProducts(state) {
            return state.selectedProducts
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
    },
    actions: {
        getProducts(context) {
            context.state.show = true;
            context.commit('setProducts', [])
            return axios.get('/v2/products/products', {params: context.state.filter})
                .then(res => {
                    context.commit('setProducts', res.data.content.data)
                })
                .finally(() => {
                    context.state.show = false
                })
        }
    }
}