import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        search: {
            id: '',
            code: '',
            barcode: '',
            stock: '',
            tax_rate: '',
            is_not_tax: false,
            brands: [],
            name: '',
            status: '',
            suppliers: [],
            hbSku: '',
            hbId: '',
            tySku: '',
            tyId: '',
            limit: 25,
            page: 1,
            selectedOptions: []
        },
        products: [],
        productScanPrices: [],
        pagination: null,
        editProduct: {},
        filterView: [
            {
                text: 'Tedarikçi Ürünleri',
                value: 'supplier-product'
            },
            {
                text: 'Mağaza Bilgileri',
                value: 'store-product'
            },
            {
                text: 'Ürün Detayları',
                value: 'product-detail'
            },
            {
                text: 'Yayımevleri',
                value: 'product-publishers'
            },
            {
                text: 'Kategoriler',
                value: 'product-categories'
            },
        ],
        selectedFilter: [],
        optionsView: [
            {
                text: 'Çoklu Barkod',
                value: 'multiBarcodes'
            },
            {
                text: 'Çoklu barkod eşleşme bekleyen',
                value: 'multiBarcodesWaitingToMatch'
            },
        ],
    },
    getters: {
        _search(state) {
            return state.search
        },
        _products(state) {
            return state.products
        },
        _productScanPrices(state) {
            return state.productScanPrices
        },
        _pagination(state) {
            return state.pagination
        },
        _editProduct(state) {
            return state.editProduct
        },
        _filterView(state) {
            return state.filterView
        },
        _selectedFilter(state) {
            return state.selectedFilter
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        setPagination(state, data) {
            delete data.data
            state.pagination = data
        },
        setProductScanPrices(state, prices) {
            state.productScanPrices = prices
        },
        addProduct(state, product) {
            state.products.unshift(product)
        },
        setProduct(state, product) {
            let index = state.products.findIndex(item => item.id == product.id)
            if (index > -1) state.products.splice(index, 1, product)
        },
        setProductBarcodes(state, data) {
            let product = state.products.find(item => item.id == data.product_id)
            if (product) product.barcodes.push(data)
        },
        updateProductBarcode(state, data) {
            let product = state.products.find(item => item.id == data.product_id)
            if (product) {
                let barcodeIndex = product.barcodes.findIndex(item => item.id == data.id)
                if (barcodeIndex > -1) {
                    product.barcodes.splice(barcodeIndex, 1, data)
                } else {
                    product.barcodes.push(data)
                }
            }
        },
        setEditProduct(state, product) {
            state.editProduct = {...product}
        },
        addEditProductSubProduct(state, subProduct) {
            if (state.editProduct.sub_products) {
                state.editProduct.sub_products.push(subProduct)
            } else {
                state.editProduct.sub_products = [subProduct]
            }
        },
        updateEditProductProductSubProduct(state, pivot) {
            let subProduct = state.editProduct.sub_products.find(subproduct => subproduct.pivot.id == pivot.id)
            if (subProduct) subProduct.pivot = pivot
        },
        destroyEditProductSubProduct(state, id) {
            let subProductIndex = state.editProduct.sub_products.findIndex(subproduct => subproduct.pivot.id == id)
            if (subProductIndex > -1) state.editProduct.sub_products.splice(subProductIndex, 1)
        },
        deleteProduct(state, productId) {
            let index = state.products.findIndex(item => item.id == productId)
            if (index > -1) state.products.splice(index, 1)
        },
        deleteImage(state, productImageId) {
            state.products.forEach(product => {
                let id = product.images.findIndex(image => image.id == productImageId)
                if (id > -1) {
                    product.images.splice(id, 1)
                }
            })
        },
        addNewImages(state, data) {
            let product = state.products.find(product => product.id == data.productId)
            console.log(product)
            if (product) {
                data.images.forEach(image => {
                    product.images.push(image)
                })
            }
        },
        setSearchNull(state) {
            state.search = {
                id: '',
                code: '',
                barcode: '',
                stock: '',
                tax_rate: '',
                is_not_tax: false,
                brands: [],
                name: '',
                status: '',
                suppliers: [],
                hbSku: '',
                hbId: '',
                tySku: '',
                tyId: '',
                limit: 25,
                page: 1,
            }
        },
        deleteBrand(state, key) {
            state.search.brands.splice(key, 1)
        }
    },
    actions: {
        getAll(context) {
            return axios.post('/products/all', context.state.search)
                .then(res => {
                    context.commit('setProducts', res.data.data)
                    context.commit('setPagination', res.data)
                    return 'success'
                })
                .catch(error => {
                    alert('Ürün Çekme Başarısız')
                    return 'danger'
                })
        },
        disableProductImages(context, productImageId) {
            return axios.put('product/image/' + productImageId, {status: 4})
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('deleteImage', productImageId)
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    }
                })
                .catch(() => {
                    return {
                        title: 'Resim Silme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        storeProduct(context, product) {
            return axios.post('/product', product)
                .then(res => {
                    if (res.data.status == 'success') {
                        product = res.data.data
                        context.commit('setEditProduct', product)
                        context.commit('addProduct', product)
                        if (product.isBundle && product.sub_products.length < 1) {
                            const subProducts = context.rootGetters['productV2/_selectedProducts']
                            console.log(subProducts);
                            subProducts.forEach(item => context.dispatch('storeProductSubProduct', item))
                        }
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Ürün Ekleme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        updateProduct(context, product) {
            return axios.put('/product/' + product.id, product)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setProduct', res.data.data)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Ürün Güncelleme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        deleteProduct(context, productId) {
            return axios.delete('/product/' + productId)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('deleteProduct', productId)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Ürün silme başarısız',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        storeProductBarcode(context, data) {
            return axios.post('/product/barcode', data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setProductBarcodes', res.data.data)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        message: 'Ürün Barkod Ekleme Hatası',
                        status: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        showProductBarcodes(context, product_id) {
            return axios.get('/product/barcode/' + product_id)
                .then(res => {
                    return res.data
                })
                .catch(() => {
                    return {
                        message: 'Ürün Barkodları Çekme Hatası',
                        status: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        updateProductBarcode(context, data) {
            return axios.put('/product/barcode/' + data.id, data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('updateProductBarcode', res.data.data)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        message: 'Ürün Barkod Güncelleme Hatası',
                        status: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        addTyMatchingProduct(context, data) {
            return axios.post('/product/tymatching/' + data.productId, data)
                .then(res => {
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Eşleşme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        addHbMatchingProduct(context, data) {
            return axios.post('/product/hbmatching/' + data.productId, data)
                .then(res => {
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Eşleşme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        addAzMatchingProduct(context, data) {
            return axios.post('/product/azmatching/' + data.productId, data)
                .then(res => {
                    return res.data
                })
                .catch(() => {
                    return {
                        title: 'Eşleşme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        saveProductImages(context, payload) {
            let data = new FormData()
            data.append('product_id', payload.productId)
            payload.images.forEach(item => {
                data.append('images[]', item)
            })
            return axios.post('product/image/add', data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('addNewImages', {
                            productId: payload.productId,
                            images: res.data.data
                        })
                    }
                    return {
                        title: res.data.message,
                        variant: res.data.status,
                        icon: 'XCircleIcon',
                    }
                })
                .catch(() => {
                    return {
                        title: 'Resim Ekleme Hatası',
                        variant: 'danger',
                        icon: 'XCircleIcon',
                    }
                })
        },
        storeProductSubProduct(context, product) {
            if (context.state.editProduct) {
                if (context.state.editProduct.id) {
                    let data = {
                        product_id: context.state.editProduct.id,
                        sub_product_id: product.id
                    }
                    return axios.post('/product/subproduct', data)
                        .then(res => {
                            if (res.data.status == 'success') {
                                context.commit('addEditProductSubProduct', {
                                    ...product,
                                    pivot: res.data.data
                                })
                            }
                            return res.data
                        })
                        .catch(() => {
                            return {
                                status: 'error',
                                message: 'hata oluştu'
                            }
                        })
                }
                return {
                    status: 'error',
                    message: 'id yok'
                }
            }
            return {
                status: 'error',
                message: 'ürün yok yok'
            }
        },
        updateProductSubProduct(context, data) {
            return axios.put('/product/subproduct/' + data.id, data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('updateEditProductProductSubProduct', res.data.data)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        message: 'Alt Ürün Güncelleme Hatası',
                        status: 'error',
                        icon: 'error',
                    }
                })
        },
        destroyProductSubProduct(context, id) {
            return axios.delete('/product/subproduct/' + id)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('destroyEditProductSubProduct', id)
                    }
                    return res.data
                })
                .catch(() => {
                    return {
                        message: 'Alt Ürün Silme Hatası',
                        status: 'error',
                        icon: 'error',
                    }
                })
        },
        getProductPrices(context) {
            axios.post('/product/price/scan', {product_ids: context.rootGetters['order/_getAllProductId']})
                .then(res => {
                    context.commit('setProductScanPrices', res.data)
                })
        },
    }
}
